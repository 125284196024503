import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// import 'swiper/swiper-bundle.css'

// Import Bootstrap CSS and JS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

createApp(App).use(router).mount('#app')
