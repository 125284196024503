<template>
  <div id="main-container">
    <header>
      <table id="header-table">
        <tr>
          <td>
            <p><a :href="baseUrl"><img src="/images/logo.png" alt="CasaMartian" id="logo" /></a></p>
            <!--p id="slogan">Smart Home Technologies</p-->
          </td>
          <td>
            <nav>
              <ul>
                <!--li><router-link to="/">Home</router-link></li>
                <li><router-link to="/products">Products</router-link></li-->
                <li><router-link to="/">Products</router-link></li>
                <!--li><router-link to="/service">Service</router-link></li-->
                <li><router-link to="/about">About</router-link></li>
              </ul>
            </nav>
          </td>
        </tr>
      </table>
    </header>

    <router-view />
    <!--router-view :key="$route.path" /-->

    <footer>
      <div id="gpt-box">
        <div id="gpt-resize-handle" onmousedown="window.resizeChatbox(event)"></div>
        <div id="gpt-header" onclick="window.toggleChat()">
          <!--span id="gpt-close-btn">&minus;</span-->
          <!--span id="gpt-close-btn">&rsaquo;</span-->
          <span id="gpt-close-btn">&#8735;</span>
          Talk to Our ChatGPT Martian!
        </div>
        <div id="gpt-body">
          <div id="gpt-messages">
            <div class="gpt-reply">
                <div class="gpt-self">Martine:</div>
                <div class="gpt-msg"><span>Welcome! How can I assist you today?</span></div>
            </div>
          </div>
          <div id="gpt-oper">
            <input type="text" id="gpt-input" placeholder="Type a message..." onkeyup="if(event.keyCode === 13) window.dialogue()">
            <button onclick="window.dialogue()" id="gpt-send-btn"><span id="gpt-btn-text">Send</span><span id="gpt-btn-symbol">&#8634;</span></button>
          </div>
        </div>
      </div>

      <div style="height: 100px;">
        <p style="margin-top: 50px;">© 2023 CasaMartian Smart Home</p>
      </div>
    </footer>
  </div>
</template>

<script>
//import { toggleChat, sendMessage } from "@/liveChat.js"; 
import '@/chatbox.css';

export default {
  name: "App",
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    };
  },
  /*methods: {
    toggleChat,
    sendMessage
  }*/
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#main-container {
  width: calc(100vw * 6.5 / 8.5);
  max-width: 15in;
}

#header-table {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 1%;
  border: 0px;
}

#logo {
  float: left;
  display: block;
  width: 320px;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  #main-container {
    width: 100%;
  }

  #header-table {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    border: 0px;
  }

  #logo {
    width: 220px;
  }
}

#slogan {
  clear: both;
  margin-left: 12%;
  text-align: left;
}

nav {
  padding-top: 30px;
  text-align: right;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

li {
  display: inline-block;
  margin: 0 10px;
}
</style>
