import { createRouter, createWebHashHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import ProductsView from '../views/ProductsView.vue'
//import ServiceView from '../views/ServiceView.vue'
import AboutView from '../views/AboutView.vue'

const routes = [
  /*
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  */
  {
    path: '/',
    name: 'ProductsView',
    component: ProductsView
  },
  /*
  {
    path: '/products',
    name: 'ProductsView',
    component: ProductsView
  },
  {
    path: '/service',
    name: 'ServiceView',
    component: ServiceView
  },
  */
  {
    path: '/about',
    name: 'AboutView',
    component: AboutView
  },

  { path: '/:id?', component: () => import('../views/ProductsView.vue') },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
