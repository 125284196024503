<template>
  <div class="Products">
    <div v-if="!selectedItem">
      <div class="intro">
        <p>
          In the unfolding panorama of tomorrow's home evolution, CasaMartian introduce a touch of Martian innovation to Earthly comforts.
          Diving into a world where everyday luxury meets cutting-edge technology, our signature line-up promises not just a smarter bathroom experience, 
          but a transformative journey to redefine what you thought possible for home.
        </p>
      </div>

      <div class="products-grid" >
        <div v-for="item in products" :key="item.id" @click="selectProduct(item)" class="product-card" >
          <img :src="'./images/' + productImages[item.id][0]" :alt="item.name" class="card-image" />
          <!--h5 class="product-info">{{ item.name.split(' Smart Toilet')[0] }}<br>Smart Toilet{{ item.name.split('Smart Toilet')[1] }}</h5-->
          <h5 class="card-desc">{{ item.name }}<br>{{ item.type }}</h5>
          <div class="card-arrow">&raquo;</div> 
        </div>
      </div>

      <div class="intro">
        <p>
          But our commitment doesn't end at ground-breaking products. We pride ourselves on providing meticulous professional installation services, 
          ensuring a seamless integration into your living spaces. Moreover, with every purchase, we instill peace of mind through our 3-year warranty, 
          underlining our belief in the enduring quality of our offerings.
        </p>
        <p>
          Why just live, when you can live smarter? Why merely exist in the present, when you can thrive in the future? With CasaMartian, 
          step confidently into the next era of home opulence.
        </p>
        <p><strong>Elevate Every Moment. Choose CasaMartian.</strong></p>
      </div>
    </div>

    <!--component :is="selectedProductComponent" :product="selectedItem" v-else @back="selectedItem = null" /-->
    <component :is="selectedProductComponent" v-else @back="selectedItem = null" />
  </div>
</template>

<script>
//import LuxuryToiletPage from "../components/LuxuryToiletPage.vue";
import ComfyToiletPage from "../components/ComfyToiletPage.vue";
import EcoToiletPage from "../components/EcoToiletPage.vue";
import SmartSeatPage from "../components/SmartSeatPage.vue";

import '@/allpage.css';

export default {
  name: "ProductsView", // replace 'PageName' with the actual name of the component
  /*mounted() {
    if (window.matchMedia("(max-width: 768px), (max-height: 768px)").matches) {
      document.getElementById("chat-container").style.display = "none";
    }
  },*/
  components: {
    //LuxuryToiletPage,
    ComfyToiletPage,
    EcoToiletPage,
    SmartSeatPage,
  },
  data() {
    return {
      selectedItem: null,
      products: [
        //{ id: "p1", name: "Regalia Elegance Smart Toilet", description: "Brief description..." },
        { id: "p2", name: "Noble Comfort", type: "Smart Toilet", description: "" },
        { id: "p3", name: "Elegant Essence", type: "Smart Toilet", description: "" },
        { id: "p4", name: "Modern Halo", type: "Smart Toilet Seat", description: "" },
      ],
      productImages: {
        //p1: ["pic_lux_1.jpg"],
        p2: ["pic_q901_1.png"],
        p3: ["pic_hk9506_1.png"],
        p4: ["pic_ak6607v_1.png"],
      },
    };
  },
  computed: {
    selectedProductComponent() {
      if (!this.selectedItem) return null;

      switch (this.selectedItem.id) {
        //case 'p1':
        //  return 'LuxuryToiletPage';
        case 'p2':
          return 'ComfyToiletPage';
        case 'p3':
          return 'EcoToiletPage';
        case 'p4':
          return 'SmartSeatPage';
        default:
          return null;
      }
    }
  },
  // this created() could be taken off without problem, but potentially if someone shares a product link, this could help receiver to locate the product directly
  created() {
    //const { id } = this.$route.query;
    const { id = null } = this.$route.params;
    if (id) {
      this.selectedItem = this.products.find(item => item.id === id);
    } 
  },
  watch: {
    //'$route.query.id'(id) {
    '$route.params.id'(id) {   // eventually find this key of the solution to the problem that the 'Products' menu cannot have been triggered
      // Find the product with the given id
      this.selectedItem = this.products.find(item => item.id === id);
    },
  },
  methods: {
    // Your component methods go here
    selectProduct(item) {
      //this.selectedItem = item;
      this.$router.push(`/${item.id}`);
      
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    },
  },
};
</script>
  
<style scoped>
  /* for product-grid */
  .products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 0;
    justify-content: center; /* Add this line to center the grid items */
    margin: 0 auto; /* And/Or use this to center the grid itself */
    max-width: 960px; /* You can set a max-width to prevent the grid from becoming too wide */
  }

  .product-card {
    margin: 2% 3%;
    border: 1px solid #ddd;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    position: relative;
  }

  .card-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .card-desc {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .card-arrow {
    position: absolute;
    right: 16px; /* Adjust as needed */
    bottom: 10px; /* Adjust as needed */
    font-size: 24px; /* Adjust as needed */
    color: #2c3e50;
  }
</style>
  