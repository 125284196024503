<template>
  <div class="About">
    <div class="intro">
      <p>
        Inspired by the future, we're not just driven by technology but by vision and passion. 
        Our mission is to help millions of homes charm with modern technology and live with more elegance and finesse.       
      </p>
      <p>
        Smart Home is of products and also experiences. Crafted with precision and pursuit of aesthetic, 
        our elite range of top-notch smart toilets and toilet seats stands as a testament to this commitment. Beyond delivering exceptional products, 
        our expertise shines in providing meticulous installation, paving the way for a flawless embrace of intelligent living. 
        Behind this ambition stands our team — a passionate group of futurists and tech enthusiasts, 
        each devoted to orchestrating a tomorrow where every home harmonious with innovations.      
      </p>
      <p>
        We're not just about selling products; we're about providing holistic, worry-free services. From the moment you choose CasaMartian, 
        we support you every step of the way, from installation to troubleshooting. We are ready to answer your queries and resolve your concerns promptly and efficiently.      
      </p>
      <p>
        We also partner with various channels to make our products widely accessible. 
        Whether you're a homeowner looking to upgrade your personal space or a business interested in reselling our products, we look forward to serving you.      
      </p>
      <p>
        At CasaMartian, we believe in the power of technology to transform everyday living. 
        We invite you to join us on this exciting journey towards creating smarter and more comfortable homes.      
      </p>
    </div>

    <div class="video-container">
      <VideoPlayer :src="'/path/to/video_intro.mp4'" />
    </div>

    <div class="intro">
      <h4>Contact Us</h4>
      <p>
        If you have any questions or require assistance, don't hesitate to send us an email or chat directly with our AI-powered 24x7 multi-lingual service representative. 
        You can use any language like française, italiana, 'עברית', 中文, 'فارسی', Türkçe, 'العربية', русский.
      </p>
      <p>Email: info@CasaMartian.com</p>
      <p>
        If you need our assistance urgently, please ask our ChatGPT service representative for a phone number.
      </p>

      <h4>Frequently Asked Questions</h4>
      <p>
        <strong>Q:</strong> Why is UL safety certification important?<br>
        <strong>A:</strong> UL (Underwriters Laboratories) certification is a highly recognized safety standard worldwide. 
        It signifies that a product has been tested thoroughly for safety risks and that it complies with established safety standards. 
        This certification is important because it ensures the product will operate safely under normal conditions and does not pose a risk of fire or electric shock. 
        For our smart toilets, having a UL certification means they have been checked rigorously for safety and reliability, providing peace of mind to our customers.
      </p>
    </div>

    <ContactForm />
  </div>
</template>


<script>
/*import VideoPlayer from "../components/VideoPlayer.vue";
import ContactForm from "../components/ContactForm.vue";
import FAQ from "../components/FAQ.vue";*/

//import { toggleChat, sendMessage } from "../liveChat.js"; 
//import '@/chatbox.css';
import '@/allpage.css';

export default {
  name: "AboutView",
  /*
  methods: {
    toggleChat,
    sendMessage
  },
  components: {
    VideoPlayer,
    ContactForm,
    FAQ,
  },
  */
};
</script>
